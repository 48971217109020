













































































import type { PropType } from '@nuxtjs/composition-api';
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  toRef,
  useContext,
  watch
} from '@nuxtjs/composition-api';
import { SfHeading, SfSidebar } from '@storefront-ui/vue';

import { getPassiveNodes } from '~/diptyqueTheme/helpers/getPassiveNodes';

export default defineComponent({
  name: 'VaimoSidebar',
  components: {
    SfSidebar,
    SfHeading
  },
  props: {
    visible: Boolean,
    overlay: Boolean,
    nested: Boolean,
    /* Tabs for slider in title [{ name, id, componentName }]*/
    tabs: {
      required: false,
      type: Array as PropType<
        {
          name: string;
          id: number;
          componentName: string;
        }[]
      >,
      default: () => []
    },
    position: {
      required: false,
      type: String,
      default: 'left',
      validator: (position: string) => ['left', 'right'].includes(position)
    },
    hide: {
      required: false,
      /* eslint-disable @typescript-eslint/no-empty-function */
      default: () => {},
      type: Function
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    headingLevel: {
      type: Number,
      default: 4
    },
    activeTabIndex: {
      type: [Number, null],
      default: null
    }
  },
  emits: ['handleTabClick'],
  setup(props, { emit }) {
    const {
      app: { $gtm }
    } = useContext();
    const activeTab = ref(null);
    const activeTabIndexValue = toRef(props, 'activeTabIndex');
    const sidebar = ref(null);
    const tabRefs = ref(null);
    const passiveElements: any = ref([]); // Node list for accessibility
    watch(
      () => activeTabIndexValue.value,
      (newValue) => {
        nextTick(() => {
          activeTab.value = props.tabs[newValue]?.id;
        });
      },
      { immediate: true }
    );
    const onTabClick = (tab) => {
      activeTab.value = tab.id;
      emit('handleTabClick', tab);
    };

    const onKeyDown = (event) => {
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        const currentIndex = props.tabs.findIndex(
          (tab) => tab.id === activeTab.value
        );
        if (currentIndex !== -1) {
          let newIndex;
          if (event.key === 'ArrowLeft') {
            newIndex =
              (currentIndex - 1 + props.tabs.length) % props.tabs.length;
          } else {
            newIndex = (currentIndex + 1) % props.tabs.length;
          }
          const newTab = props.tabs[newIndex];
          activeTab.value = newTab.id;
          emit('handleTabClick', newTab);
          nextTick(() => {
            tabRefs.value[newIndex].focus();
          });
        }
      }
    };

    const hideHandler = (hide) => {
      $gtm.push({
        event: 'click_close_popin'
      });

      if (typeof hide === 'function') {
        hide();
      }
    };

    const tabName = computed(() => {
      if (activeTab.value !== null) {
        const tab = props.tabs.find((t) => t.id === activeTab.value);
        if (tab) {
          return tab.name === 'Account'
            ? 'ACCOUNT'
            : tab.name.startsWith('Shopping Bag')
            ? 'CART'
            : tab.name.toUpperCase();
        }
      }
      return '';
    });
    // Accessibility support: hide elements from screen reader
    const lockFocus = () => {
      const lastParent = document.querySelector('#__nuxt');
      passiveElements.value = getPassiveNodes(sidebar.value.$el, lastParent);
      passiveElements.value.forEach((el) => {
        el.ariaHidden = true;
      });
    };

    // Accessibility support: reveal elements for screen reader
    const unlockFocus = () => {
      passiveElements.value.forEach((el) => {
        el.ariaHidden = false;
      });
    };

    onMounted(() => {
      if (props.visible) lockFocus();
    });

    onBeforeUnmount(() => {
      unlockFocus();
    });

    return {
      onTabClick,
      activeTab,
      hideHandler,
      sidebar,
      unlockFocus,
      tabName,
      onKeyDown,
      tabRefs
    };
  }
});
