import { getProductCapacity, getProductVariantName } from '~/diptyqueTheme/helpers/productGetters';
import type { Product } from '~/modules/catalog/product/types';
import type { ProductInterface } from '~/modules/GraphQL/types';

export const hasUniqueCapacity = (product: Product | ProductInterface) => {
  if (!product.capacity || product.capacity.length === 0) return false;

  return getProductVariantName(product) !== getProductCapacity(product);
};

export const hasValidVariantName = (product: Product | ProductInterface) => {
  if (!product.variant_name || product.variant_name.length === 0) return false;
  const variant = getProductVariantName(product);

  return Boolean(variant && variant.length);
};
