export default {
  fr_fr: {
    terms_link: 'https://www.diptyqueparis.com/fr_fr/c/conditions-generales-de-ventes-de-produits-en-ligne.html',
    policy_link: 'https://www.diptyqueparis.com/fr_fr/c/politique-de-confidentialite-en-ligne-de-diptyque.html',
    zendesk_link: 'https://serviceclient.diptyqueparis.com/hc/fr-fr',
    contact_us_link: 'https://serviceclient.diptyqueparis.com/hc/fr-fr/requests/new',
    subscription_cookie_link: 'https://www.diptyqueparis.com/fr_fr/c/politique-de-cookies.html',
    email: 'serviceclient@diptyqueparis.com'
  },
  en_uk: {
    terms_link: 'https://www.diptyqueparis.com/en_uk/c/terms-conditions-for-online-product-sales.html',
    policy_link: 'https://www.diptyqueparis.com/en_uk/c/online-privacy-policy.html',
    zendesk_link: 'https://customerservice.diptyqueparis.com/hc/en-gb',
    contact_us_link: 'https://customerservice.diptyqueparis.com/hc/en-gb/requests/new',
    subscription_cookie_link: 'https://www.diptyqueparis.com/en_uk/c/cookie-policy.html',
    email: 'customerservice@diptyqueparis.com'
  },
  en_us: {
    terms_link: 'https://www.diptyqueparis.com/en_us/c/terms-conditions-for-online-product-sales.html',
    policy_link: 'https://www.diptyqueparis.com/en_us/c/online-privacy-policy.html',
    zendesk_link: 'https://customerserviceus.diptyqueparis.com/hc/en-us',
    contact_us_link: 'https://customerserviceus.diptyqueparis.com/hc/en-us/requests/new',
    conditions_of_sale_link: 'https://www.diptyqueparis.com/en_us/c/terms-conditions-for-online-product-sales.html'
  },
  fr_us: {
    terms_link: 'https://www.diptyqueparis.com/fr_us/c/conditions-generales-de-ventes-de-produits-en-ligne.html',
    policy_link: 'https://www.diptyqueparis.com/fr_us/c/politique-de-confidentialite-en-ligne-de-diptyque.html',
    zendesk_link: 'https://customerserviceus.diptyqueparis.com/hc/fr',
    contact_us_link: 'https://customerserviceus.diptyqueparis.com/hc/fr/requests/new',
    conditions_of_sale_link: 'https://www.diptyqueparis.com/fr_us/c/conditions-generales-de-ventes-de-produits-en-ligne.html'
  },
  fr_eu: {
    terms_link: 'https://www.diptyqueparis.com/fr_eu/c/conditions-generales-de-ventes-de-produits-en-ligne.html',
    policy_link: 'https://www.diptyqueparis.com/fr_eu/c/politique-de-confidentialite-en-ligne-de-diptyque.html',
    zendesk_link: 'https://serviceclienteurope.diptyqueparis.com/hc/fr-be',
    contact_us_link: 'https://serviceclienteurope.diptyqueparis.com/hc/fr-be/requests/new',
    subscription_cookie_link: 'https://www.diptyqueparis.com/fr_eu/c/politique-de-cookies.html',
    email: 'serviceclienteurope@diptyqueparis.com'
  },
  en_eu: {
    terms_link: 'https://www.diptyqueparis.com/en_eu/c/terms-conditions-for-online-product-sales.html',
    policy_link: 'https://www.diptyqueparis.com/en_eu/c/online-privacy-policy.html',
    zendesk_link: 'https://customerserviceeurope.diptyqueparis.com/hc/en-be',
    contact_us_link: 'https://customerserviceeurope.diptyqueparis.com/hc/en-be/requests/new',
    subscription_cookie_link: 'https://www.diptyqueparis.com/en_eu/c/cookie-policy.html',
    email: 'customerserviceeurope@diptyqueparis.com'
  },
  de_eu: {
    terms_link: 'https://www.diptyqueparis.com/de_eu/c/agbs-online-verkauf.html',
    policy_link: 'https://www.diptyqueparis.com/de_eu/c/online-datenschutzrichtlinie.html',
    zendesk_link: 'https://kundenservice.diptyqueparis.com/hc/de',
    contact_us_link: 'https://kundenservice.diptyqueparis.com/hc/de/requests/new',
    subscription_cookie_link: 'https://www.diptyqueparis.com/de_eu/c/cookie-politik.html',
    email: 'kundenservice@diptyqueparis.com'
  },
  it_eu: {
    terms_link: 'https://www.diptyqueparis.com/it_eu/c/termini-e-condizioni-generali-di-vendita-dei-prodotti.html',
    policy_link: 'https://www.diptyqueparis.com/it_eu/c/informativa-sulla-privacy-online.html',
    zendesk_link: 'https://servizioclienti.diptyqueparis.com/hc/it',
    contact_us_link: 'https://servizioclienti.diptyqueparis.com/hc/it/requests/new',
    subscription_cookie_link: 'https://www.diptyqueparis.com/it_eu/c/informativa-sui-cookie.html',
    email: 'servizioclienti@diptyqueparis.com'
  },
  es_eu: {
    terms_link: 'https://www.diptyqueparis.com/es_eu/c/terminos-y-condiciones-de-venta-online.html',
    policy_link: 'https://www.diptyqueparis.com/es_eu/c/politica-de-privacidad-en-linea.html',
    zendesk_link: 'https://servicioalcliente.diptyqueparis.com/hc/es-es',
    contact_us_link: 'https://servicioalcliente.diptyqueparis.com/hc/es-es/requests/new',
    subscription_cookie_link: 'https://www.diptyqueparis.com/es_eu/c/politica-de-cookies.html',
    email: 'servicioalcliente@diptyqueparis.com'
  },
  ja_jp: {
    terms_link: 'https://www.diptyqueparis.com/ja_jp/c/terms-conditions-for-online-product-sales.html',
    policy_link: 'https://www.diptyqueparis.com/ja_jp/c/online-privacy-policy.html',
    zendesk_link: 'https://diptyquejp.zendesk.com/hc/ja',
    contact_us_link: 'https://customerservicejp.diptyqueparis.com/hc/ja/requests/new',
    conditions_of_sale_link: 'https://www.diptyqueparis.com/ja_jp/c/terms-conditions-for-online-product-sales.html'
  },
  en_hk: {
    terms_link: 'https://www.diptyqueparis.com/en_hk/c/terms-conditions-for-online-product-sales.html',
    policy_link: 'https://www.diptyqueparis.com/en_hk/c/online-privacy-policy.html',
    zendesk_link: 'https://customerservicehk.diptyqueparis.com/hc/en-hk',
    contact_us_link: 'https://customerservicehk.diptyqueparis.com/hc/en-hk/requests/new',
    conditions_of_sale_link: 'https://www.diptyqueparis.com/en_hk/c/terms-conditions-for-online-product-sales.html'
  },
  zh_hk: {
    terms_link: 'https://www.diptyqueparis.com/zh_hk/c/terms-conditions-for-online-product-sales.html',
    policy_link: 'https://www.diptyqueparis.com/zh_hk/c/online-privacy-policy.html',
    zendesk_link: 'https://customerservicehk.diptyqueparis.com/hc/zh-hk',
    contact_us_link: 'https://customerservicehk.diptyqueparis.com/hc/zh-hk/requests/new',
    conditions_of_sale_link: 'https://www.diptyqueparis.com/zh_hk/c/terms-conditions-for-online-product-sales.html'
  }
};
