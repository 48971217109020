/**
 * This function provides sibling and parent sibling Nodes collection.
 * The function meant for accessibility reason to hide unused nodes from Screen Reader.
 * Use with caution - heavy.
 * @param {Element | null, Element | null} : current Node, last parent node
 * @returns NodeList
 */

export const getPassiveNodes = (element: Element | null, lastParent: Element | null): NodeList => {
  const collection:any = [];
  let elem = element;
  if (elem && lastParent) {
    const findAllSiblings = (el) => {
      let currentSiblings = [...el.parentElement.children].filter(child => child !== el);
      collection.push(...currentSiblings);
      elem = el.parentElement;
    }
    while (elem !== lastParent) {
      findAllSiblings(elem);
    }
  }

  return collection;
}
