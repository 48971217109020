





































































import {
  computed,
  defineComponent,
  ref,
  ssrRef,
  useContext,
  useFetch
} from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

import contentfulEntriesIds from '~/diptyqueTheme/config/contentfulEntries';
import type { CountDownDataInterface } from '~/diptyqueTheme/types/components';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';

export default defineComponent({
  name: 'VaimoPromoBanner',
  components: {
    CountdownTimer: () => import('atoms/CountdownTimer.vue'),
    SfLink
  },
  setup() {
    const { query, queryResponse } = useContentfulGQL('byKeyPromoBanner');
    const promoBannerText = ref('');
    const promoBannerLink = ref('');
    const promoBannerRef = ref(null);
    const promoBannerContentRef = ref(null);
    const countDownData = ref<CountDownDataInterface>({});
    const isCountdownExpired = ssrRef(false);
    const greyColor = '#F8F8F8';
    const blackColor = '#000000';
    const promoBackgroundColor = ref(greyColor);
    const promoTextColor = ref(blackColor);
    const { i18n, app } = useContext();
    const apiState = app.$vsf.$magento.config.state;

    const isBannerHidden = ssrRef(apiState.getPromoBannerClosed());
    const showPromo = ssrRef(true);

    const isCountDownEnabled = computed(
      () =>
        countDownData.value.countdownEndDataTime &&
        countDownData.value.countdownShowHide &&
        !isCountdownExpired.value
    );

    useFetch(async () => {
      await query('getMicroDataBunchByKey', {
        key: contentfulEntriesIds.PROMO_BANNER_MICRODATA_ITEM_KEY,
        locale: i18n.localeProperties.contentfulLocale
      });
      const promoBannerContent =
        queryResponse.value?.microdataBunchCollection?.items?.[0]
          ?.entriesCollection?.items;

      if (promoBannerContent && promoBannerContent.length) {
        promoBannerContent.forEach(
          ({
            key,
            value,
            longValue,
            countdownEndDataTime,
            countdownShowHideSeconds,
            countdownShowHide,
            promoBannerBackgroundColor,
            promoBannerTextColor
          }) => {
            if (key === 'promo-banner-text') {
              let text = value;
              if (text === '##') text = ''; // Make '##' empty string to make it possible to hide banner for specific locales
              promoBannerText.value = text;

              if (countdownEndDataTime) {
                countDownData.value = {
                  countdownEndDataTime,
                  countdownShowHideSeconds,
                  countdownShowHide,
                  countDownExpiredFallBackText: longValue
                };
              }
              // To avoid CLS during calculation in CountdownTimer component
              isCountdownExpired.value = countdownEndDataTime
                ? new Date(countdownEndDataTime).getTime() -
                    new Date().getTime() <=
                  0
                : true;

              promoBackgroundColor.value =
                promoBannerBackgroundColor || greyColor;

              promoTextColor.value = promoBannerTextColor
                ? blackColor
                : '#ffffff';
            } else if (key === 'promo-banner-link') {
              promoBannerLink.value = value;
            }
          }
        );
      }
    });

    const setCountdownExpiredFlag = (flag: boolean) => {
      isCountdownExpired.value = flag;
      promoBannerText.value = countDownData.value.countDownExpiredFallBackText;
    };

    const closePromoBanner = () => {
      // eslint-disable-next-line no-magic-numbers
      const ONE_WEEK = 7 * 24 * 60 * 60;
      apiState.setPromoBannerClosed(true, {
        maxAge: ONE_WEEK
      });

      showPromo.value = false;
    };

    const handleTransitionEnd = () => {
      isBannerHidden.value = true;
    };

    return {
      promoBannerRef,
      countDownData,
      isCountDownEnabled,
      promoBannerContentRef,
      isBannerHidden,
      showPromo,
      promoBannerText,
      promoBannerLink,
      setCountdownExpiredFlag,
      closePromoBanner,
      promoBackgroundColor,
      promoTextColor,
      handleTransitionEnd
    };
  }
});
