var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideMenuLayerBlock),expression:"hideMenuLayerBlock"}],staticClass:"vaimo-header-top-nav z-10 w-full top-[0px] bg-blanc border-b-[1px] border-secondary shadow-[0_0.5px_0px_0px_secondary]",on:{"mouseleave":_vm.hideMenuLayerBlock}},[_c('div',{staticClass:"flex text-lg font-light py-base text-gris"},[_c('ul',{staticClass:"flex mx-auto",attrs:{"role":"menubar"}},_vm._l((_vm.getNavigation),function(item){return _c('li',{key:item.text,staticClass:"uppercase mr-[20px] __element",class:{
          active:
            _vm.currentActiveLinkID === item.id ||
            _vm.currentFocusedLinkID === item.id,
          hovering: _vm.isHovering || _vm.isMenuLayerVisible
        },attrs:{"role":"menuitem","aria-haspopup":_vm.hasSubcategories(item),"aria-expanded":_vm.currentActiveLinkID === item.id && _vm.isMenuLayerVisible
            ? 'true'
            : 'false'},on:{"mouseenter":function($event){return _vm.changeNav(item.id, item.text)},"mouseleave":_vm.removeHover,"click":function($event){_vm.isMenuLayerVisible = false},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();return _vm.changeNav(item.id, item.text)},"focusin":function($event){return _vm.setFocus(item.id)},"focusout":_vm.removeFocus}},[(item.link && item.link !== '#')?_c('SfLink',{attrs:{"link":_vm.isInternal(item.link) ? _vm.normalizeLink(item.link) : item.link},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getClickNavDetails(item.text)}},nativeOn:{"click":function($event){return _vm.getClickNavDetails(item.text)}}},[_vm._v("\n          "+_vm._s(item.text)+"\n        ")]):[_c('div',{staticClass:"without-link",attrs:{"tabindex":"0"}},[_vm._v("\n            "+_vm._s(item.text)+"\n          ")])]],2)}),0)]),_vm._v(" "),_c('slide-up-down',{staticClass:"absolute w-full bg-blanc",attrs:{"active":_vm.isMenuLayerVisible,"duration":900}},[_c('div',_vm._l((_vm.getNavigation),function(item){return _c('VaimoMenuLayer',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentActiveLinkID === item.id),expression:"currentActiveLinkID === item.id"}],key:item.id,attrs:{"data":item.children,"current-nav":_vm.currentActiveLinkName},on:{"hide":_vm.hideMenuLayerBlock}})}),1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }