




import type { PropType } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import { defineComponent } from '@nuxtjs/composition-api';

import { useNostoSession } from '~/diptyqueTheme/composable/nosto/useNostoSession';
import { mapCart, mapCustomer } from '~/diptyqueTheme/helpers/nosto';
import type { Cart, Customer } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'NostoSession',
  props: {
    customer: {
      type: Object as PropType<Customer | null>,
      default: null
    },
    cart: {
      type: Object as PropType<Cart | null>,
      default: null
    }
  },
  setup(props) {
    const currentCart = props.cart ? mapCart(props.cart) : null;
    const currentCustomer = props.customer ? mapCustomer(props.customer) : null;

    useNostoSession(currentCart, currentCustomer);
  }
});
