export default `
query GetKlevuDefaultCategoriesWithOrder {
    getKlevuDefaultCategoriesWithOrder {
        items {
            id
            name
            order
            uri
        }
    }
}
`;
