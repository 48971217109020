export const formatDate = (input: string): string => {
  const cleanedInput = input.replace(/\D/g, '');
  return cleanedInput.replace(/(\d{2})(\d{0,2})(\d{0,4})/, (match, p1, p2, p3) => {
    if (p2 === '' && p3 === '') {
      return p1;
    } else if (p3 === '') {
      return `${p1}/${p2}`;
    }
    return `${p1}/${p2}/${p3}`;
  });
};
