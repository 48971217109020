import { createEvent } from 'ics';

interface EventDataInterface {
  start?: number | string | Date;
  end: number | string | Date;
  title: string;
  description: string;
}

export const generateICSFile = async (event: EventDataInterface, filename: string = event.title): Promise<void> => {
  try {
    const _event = {
      start: new Date(event.end).getTime(),
      duration: { hours: 1 },
      title: event.title,
      description: event.description
    };
    const fileBlob = await new Promise<Blob>((resolve, reject) => {
      createEvent(_event, (error, value) => {
        if (error) {
          reject(error);
        }
        resolve(new Blob([value], { type: 'text/calendar' }));
      });
    });

    const url = URL.createObjectURL(fileBlob);

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error occurred during file generation:', error);
  }
};

export const generateGoogleCalendarLink = (event: EventDataInterface) => {
  const formatGoogleCalendarDate = (date) => {
    const isoString = date.toISOString();
    return isoString.replace(/-|:|\.\d{3}/g, '');
  };

  const startDate = new Date(event.end);
  const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);

  const formattedStartDate = formatGoogleCalendarDate(startDate);
  const formattedEndDate = formatGoogleCalendarDate(endDate);

  const title = encodeURIComponent(event.title);
  const description = encodeURIComponent(event.description);

  // eslint-disable-next-line max-len
  const calendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${formattedStartDate}/${formattedEndDate}&details=${description}&sf=true&output=xml`;

  window.open(calendarLink, '_blank');
};
