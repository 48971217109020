export default `mutation addBooxiBookingProductsToCart(
  $cart_id: String!
  $encoded_booxi_products: String!
) {
  addBooxiBookingProductsToCart(
    input: {
      cart_id: $cart_id
      encoded_booxi_products: $encoded_booxi_products
    }
  ) {
    cart {
      id
      email
      total_quantity
      freeshipping_threshold_info {
        freeshipping_enabled
        freeshipping_left
        currency_symbol
        freeshipping_amount
      }
      items {
        uid
        additional_data
        booxi_item_info {
          is_booxi
          expired_time
        }
        product {
          id
          uid
          __typename
          sku
          name
          thumbnail {
            url
            position
            disabled
            label
          }
          url_key
          price_range {
            maximum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
            minimum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
          }
        }
        quantity
      }
    }
  }
}`;
