

































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { focusTrap } from '@storefront-ui/vue/src/utilities/directives';
import NostoSession from 'atoms/NostoSession.vue';

import AccountForgotPasswordForm from '~/diptyqueTheme/components/organisms/account/parts/AccountForgotPasswordForm.vue';
import AccountLoginForm from '~/diptyqueTheme/components/organisms/account/parts/AccountLoginForm.vue';
import AccountRegistrationForm from '~/diptyqueTheme/components/organisms/account/parts/AccountRegistrationForm.vue';
import LoyaltyPushRenderer from '~/diptyqueTheme/components/templates/sections/LoyaltyPush/LoyaltyPushRenderer.vue';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  name: 'MiniCartAccount',
  components: {
    AccountLoginForm,
    AccountRegistrationForm,
    MainUserInfo: () => import('organisms/account/index.vue'),
    AccountForgotPasswordForm,
    LoyaltyPushRenderer,
    NostoSession
  },
  setup() {
    const { isAuthenticated, user } = useUser();
    const isRegistrationStep = ref(false);
    const isForgotPasswordStep = ref(false);

    const updateFocus = () => {
      // Accessibility support: recreate focus area
      const sidebar = document.querySelector('aside.sf-sidebar__aside');
      focusTrap.componentUpdated(sidebar);
    };

    const changeStep = (type) => {
      if (type === 'registration') {
        isRegistrationStep.value = !isRegistrationStep.value;
      } else if (type === 'forgot_password') {
        isForgotPasswordStep.value = !isForgotPasswordStep.value;
      } else if (type === 'login') {
        isRegistrationStep.value = !isRegistrationStep.value;
      }

      updateFocus();
    };

    return {
      changeStep,
      isAuthenticated,
      isForgotPasswordStep,
      isRegistrationStep,
      updateFocus,
      user
    };
  }
});
