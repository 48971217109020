import type { PushedCustomer as NostoCustomer } from '@nosto/types';

import type { Customer } from '~/modules/GraphQL/types';

export default function mapCustomer(customer: Customer): NostoCustomer {
  return {
    email: customer.email,
    first_name: customer.firstname,
    last_name: customer.lastname,
    newsletter: customer.is_subscribed,
    customer_reference: customer.nosto_customer_reference
  };
}
