<template>
  <div
    v-if="
      buttonVisibility && !(buttonToShowAccountCreationForm && isAuthenticated)
    "
    v-show="isVisible"
  >
    <SfButton
      v-if="!isEmail"
      class="vaimo-button"
      :type="(link || type === 'link') ? null : type"
      :link="getComputedLink"
      :target="link && getLinkTarget"
      :class="getButtonClasses"
      :disabled="isDisabled"
      v-bind="$attrs"
      v-on="$listeners"
      @keydown.enter="onClick"
      @click="onClick"
    >
      <template #default>
        <div class="vaimo-button__wrapper" :class="getWrapperClass">
          <VaimoIcon
            v-if="icon"
            :icon="icon"
            class="button-icon"
            :label="icon"
          />
          <VaimoIcon
            v-else-if="buttonToShowAccountCreationForm"
            icon="chevrondown"
            class="button-icon"
            :label="icon"
            :size="20"
          />
          <slot />
          <span v-if="additionalData" class="vaimo-button__additional-data">
            {{ additionalData }}
          </span>
        </div>
      </template>
    </SfButton>
    <VaimoNewsletterSubscription
      v-else
      hide-header
      :label="buttonText"
      :submit-button-text="emailButtonText"
      :force-show-submit="locale === 'en_uk' || forceShowSubmit"
      :countdown-email="countdownEmail"
    />
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
  toRef,
  useContext,
  useRouter
} from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

import { useUiNotification, useUiState } from '~/composables';
import { useBooxi } from '~/diptyqueTheme/composable/useBooxi';
import { useLink } from '~/diptyqueTheme/composable/useLink';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import {
  generateGoogleCalendarLink,
  generateICSFile
} from '~/diptyqueTheme/helpers/generateCalendarInvitation';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  name: 'VaimoButton',
  components: {
    SfButton,
    VaimoNewsletterSubscription: () =>
      import('organisms/VaimoNewsletterSubscription.vue')
  },
  props: {
    variant: {
      required: false,
      type: String,
      default: 'primary',
      validator: (value) =>
        [
          'primary',
          'default',
          'link',
          'circle',
          'small',
          'link with arrow',
          'link grey',
          'edito link'
        ].includes(value)
    },
    type: {
      required: false,
      type: String,
      default: 'button'
    },
    isEmail: Boolean,
    iconPosition: {
      required: false,
      type: String,
      default: 'right',
      validator: (value) => ['right', 'left'].includes(value)
    },
    labelCentered: {
      required: false,
      type: Boolean,
      default: false
    },
    fullWidth: {
      required: false,
      type: Boolean,
      default: false
    },
    link: {
      required: false,
      type: String,
      default: null
    },
    emailButtonText: {
      type: String,
      default: ''
    },
    icon: {
      required: false,
      type: String,
      default: ''
    },
    linkBehavior: {
      required: false,
      type: String,
      default: 'open in current tab',
      validator: (value) =>
        ['open in current tab', 'open in new tab'].includes(value)
    },
    preventDefault: Boolean,
    disabled: Boolean,
    loading: Boolean,
    block: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    buttonText: String,
    desktopType: {
      required: false,
      type: String,
      default: ''
    },
    buttonVisibility: {
      required: false,
      type: Boolean,
      default: true
    },
    layerContent: {
      required: false,
      type: [Object, null],
      default: null
    },
    additionalData: {
      required: false,
      type: [String, null],
      default: null
    },
    calendarData: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isBooxiButton: Boolean,
    isAddToCartButton: Boolean,
    isCalendarButton: Boolean,
    forceShowSubmit: Boolean,
    countdownEmail: Boolean,
    buttonToShowAccountCreationForm: Boolean,
    gtmAttributes: {
      type: [Object, null],
      required: false,
      default: null
    }
  },
  emits: ['show-registration-form', 'add-to-cart', 'event-click'],
  setup(props, { emit }) {
    const { normalizeLink } = useLink();
    const { openContentLayer } = useUiState();
    const { i18n, app } = useContext();
    const { triggerBooxiModal } = useBooxi();
    const locale = i18n.localeProperties?.code;
    const { isAndroid } = app.$device;
    const { isDesktop } = useScreenSize();
    const { isAuthenticated } = useUser();

    const _disabled = ref(false);
    const isDisabled = computed(() => props.disabled || _disabled.value);
    const isPreventedDefault = toRef(props, 'preventDefault');
    const isVisible = ref(true);
    const isActive = ref(false);

    const variants = [
      'primary',
      'default',
      'link',
      'circle',
      'small',
      'link with arrow',
      'link grey',
      'edito link'
    ];

    function generateButtonClass(type) {
      const prefix = 'vaimo-button__';
      const formattedClass = variants.includes(type)
        ? type.replace(/ /g, '-')
        : 'default';

      return {
        [`${prefix}${formattedClass}`]: true
      };
    }

    const getButtonClasses = computed(() => {
      const baseClasses = {
        'vaimo-button__block': props.block,
        'vaimo-button__disabled': isDisabled.value,
        'w-full': props.fullWidth,
        'vaimo-button__show-registration-form':
          props.buttonToShowAccountCreationForm
      };
      const mobileClasses =
        !isDesktop?.value || (isDesktop?.value && !props.desktopType)
          ? generateButtonClass(props.variant)
          : {};

      const desktopClasses =
        isDesktop?.value &&
        props.desktopType &&
        props.desktopType !== props.variant
          ? generateButtonClass(props.desktopType)
          : {};

      const fallbackClasses =
        !Object.keys(mobileClasses).length &&
        !Object.keys(desktopClasses).length
          ? generateButtonClass(props.variant)
          : {};

      const activeClass = {
        'vaimo-button__active': isActive.value
      };

      return {
        ...baseClasses,
        ...mobileClasses,
        ...desktopClasses,
        ...fallbackClasses,
        ...activeClass
      };
    });

    const getWrapperClass = computed(() => {
      return {
        'icon-right': props.iconPosition === 'right',
        'icon-left': props.iconPosition === 'left',
        center: props.labelCentered,
        'justify-between': props.additionalData
      };
    });

    const getLinkTarget = computed(() => {
      const targetMap = {
        'open in current tab': '_self',
        'open in new tab': '_blank'
      };

      return targetMap[props.linkBehavior];
    });

    const getComputedLink = computed(() => {
      if (
        isPreventedDefault.value ||
        isDisabled.value ||
        props.isBooxiButton ||
        props.isCalendarButton ||
        props.buttonToShowAccountCreationForm ||
        props.link === '#'
      )
        return '';
      if (props.layerContent || props.isAddToCartButton || addToCartSku.value)
        return ''; // Prevent go to next page if Layer is configured or addToCartSku is set

      if (props.link?.includes('mailto:')) {
        const mailtoIndex = props.link?.toLowerCase().indexOf('mailto:');

        return mailtoIndex === -1
          ? normalizeLink(props.link)
          : props.link?.slice(mailtoIndex).trim();
      }

      if (props.link?.includes(process.env.VSF_STORE_URL)) {
        return props.link?.replace(process.env.VSF_STORE_URL, '');
      }

      return normalizeLink(props.link);
    });

    const addToCartSku = computed(() => {
      const delimiter = '#addToCart:';
      if (!props.link?.includes(delimiter)) {
        return null;
      }

      const parts = props.link.split(delimiter);
      const minParts = 2;
      if (parts.length < minParts || !parts[1]) {
        return null;
      }

      const extractedSku = parts[1].trim();
      return extractedSku || null;
    });

    const onClick = async () => {
      emit('event-click');

      if (!getComputedLink.value) {
        if (props.isAddToCartButton) {
          emit('add-to-cart');
        } else if (addToCartSku.value) {
          await addToCartFromButton(addToCartSku.value);
        } else if (props.layerContent) {
          const { heading, content } = props.layerContent;
          openContentLayer(heading, content);
        } else if (props.isBooxiButton) {
          triggerBooxiModal();
        } else if (props.isCalendarButton) {
          const event = {
            end: props.calendarData.endDate,
            title: props.calendarData.title,
            description: props.calendarData.description
          };
          await generateICSFile(event);
          if (isAndroid) {
            generateGoogleCalendarLink(event);
          }
        } else if (props.buttonToShowAccountCreationForm) {
          emit('show-registration-form');
          isActive.value = !isActive.value;
        }
      }
      if (props.gtmAttributes) {
        app.$gtm.push(props.gtmAttributes);
      }
    };

    // hotfix for DPTQ-6056. Add to cart should be handled on the top level component.
    const { addItemToCart } = useAddToCart();
    const { getProductDetailsToAddToCart } = useProduct();
    const { send: sendNotification } = useUiNotification();
    const router = useRouter();
    const addToCartFromButton = async (sku) => {
      _disabled.value = true;
      try {
        const result = await getProductDetailsToAddToCart(
          {
            filter: {
              sku: {
                eq: sku
              }
            }
          },
          true
        );
        if (result?.items?.[0]) {
          await addItemToCart({ product: result.items[0], quantity: 1 });
        } else {
          sendNotification({
            message: i18n.t('This product is currently unavailable'),
            persist: false,
            type: 'danger',
            area: 'top'
          });
        }
      } catch (error) {
        router.push({ name: 'error' });
        console.error(error);
      } finally {
        _disabled.value = false;
      }
    };

    return {
      getLinkTarget,
      getComputedLink,
      getWrapperClass,
      getButtonClasses,
      onClick,
      locale,
      isAuthenticated,
      isVisible,
      isDisabled
    };
  }
});
</script>

<style lang="scss" scoped>
@import '@/diptyqueTheme/assets/styles/atoms/VaimoButton';
</style>
