import axios from 'axios';
import {useConfigStore} from '~/stores/config';
import {ref, useContext} from '@nuxtjs/composition-api';

export function useGetRedirect(ctx)
{
  const magentoBaseUrl = process.env.VSF_MAGENTO_BASE_URL;
  const redirectLoader = ref(false);
  const {app, app: {i18n: {locales}} = {i18n: {locales: []}}} = useContext();

  const redirectToStore = (path) => {
    const locale = path.substring(path.indexOf('_') - 2, path.indexOf('_') + 3);
    if (!locale) {
      console.log('Problem with parsing store_code');
      return;
    }
    const store = locales.find(loc => loc.code === locale);
    if (!store) {
      console.log(`Problem with parsing store, locale is ${locale}`);
      return;
    }
    app.$vsf.$magento.config.state.setStore(store.store_code);
    app.$vsf.$magento.config.state.setCurrency(store.default_display_currency_code);
    app.$vsf.$magento.config.state.setLocale(store.store_code);
    window.location.replace(path);
  }

  const getRedirectData = async () => {
    if (!isShouldRedirect()) {
      return {
        path: '',
        shouldBeRedirected: false
      };
    }
    redirectLoader.value = true;
    const configStore = useConfigStore();
    try {
      const res = await axios.post(`${magentoBaseUrl}rest/V1/geoIp/getRedirect`, {
        full_path: ctx.route.fullPath,
        current_store_code: configStore.storeConfig.store_code ?? 'en_eu'
      });
      return JSON.parse(res.data);
    } catch (err) {
      console.log(err);
      return {
        shouldBeRedirected: false
      };
    } finally {
      redirectLoader.value = false;
    }
  };

  const isShouldRedirect = () => {
    const isGoogleSuggestion = !!ctx.$cookies?.get('google_suggestions_redirect_flag');
    const wasRedirected = !!ctx.$cookies?.get('geoip_store_code');
    return !!ctx.route?.query?.utm_medium && !checkRedirectParam() && !isGoogleSuggestion && !wasRedirected;
  };

  const checkRedirectParam = function () {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    return !!urlParams.get('redirectQuoteId');
  };

  return {
    redirectToStore,
    redirectLoader,
    getRedirectData
  };
}
