

























































import {
  computed,
  defineComponent,
  ref,
  useContext,
  watch
} from '@nuxtjs/composition-api';

import { useUiState } from '~/composables';
import VaimoButton from '~/diptyqueTheme/components/atoms/VaimoButton.vue';
import Account from '~/diptyqueTheme/components/organisms/miniCart/parts/Account.vue';
import VaimoSidebar from '~/diptyqueTheme/components/organisms/VaimoSidebar.vue';
import { useBooxi } from '~/diptyqueTheme/composable/useBooxi';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import useCart from '~/modules/core/composables/useCart';
import cartGetters from '~/modules/core/getters/cartGetters';
import { useCartStore } from '~/modules/core/stores/cart';

export default defineComponent({
  name: 'VaimoMiniCart',
  components: {
    VaimoButton,
    VaimoSidebar,
    Favorites: () => import('./parts/Favorites.vue'),
    Cart: () => import('./parts/Cart.vue'),
    Account
  },
  props: {
    visible: Boolean
  },
  emits: ['close'],
  setup(_, { emit }) {
    const { sidebarActionType } = useUiState();
    const { hasOneBooxiItemInCart } = useBooxi();
    const { app } = useContext();
    const cartStore = useCartStore();
    const { cart } = useCart();
    const activeTabIndex = ref(null);
    const activeComponent = ref('Cart');
    const isMerging = computed(() => cartStore.is_data_loading);
    const TABS = {
      FAVORITES: 1,
      ACCOUNT: 2,
      CART: 3
    };
    const tabs = ref([
      {
        name: app.i18n.t('Account'),
        id: TABS.ACCOUNT,
        componentName: 'Account'
      }
    ]);

    const close = (checkIsMerging = false) => {
      if (checkIsMerging && isMerging.value) return;

      emit('close');
      activeTabIndex.value = null;
    };

    const onTabClick = (tab) => {
      activeComponent.value = tab.componentName;
    };

    const getFreeShippingThresholdInfo = computed(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return cart.value?.freeshipping_threshold_info;
    });

    const isFreeShippingEnabled = computed(
      () =>
        getFreeShippingThresholdInfo.value &&
        getFreeShippingThresholdInfo.value?.freeshipping_enabled
    );

    const getFreeShippingThresholdInfoText = computed(() => {
      if (getFreeShippingThresholdInfo.value?.freeshipping_left !== 0) {
        return app.i18n.t(
          'Only {amount} {currency} left to get free shipping.',
          {
            amount: getFreeShippingThresholdInfo.value?.freeshipping_left,
            currency: getFreeShippingThresholdInfo.value?.currency_symbol
          }
        );
      }
      return app.i18n.t('You have qualified for free shipping');
    });

    const total = computed(() => cartGetters.getTotals(cart.value).subtotal);

    watch(
      () => sidebarActionType.value,
      (newValue) => {
        if (newValue === 'account') {
          activeComponent.value = 'Account';
          activeTabIndex.value = 0;
        } else if (newValue === 'cart') {
          activeComponent.value = 'Cart';
          activeTabIndex.value = 1;
        } else {
          activeComponent.value = null;
          activeTabIndex.value = null;
        }
      },
      { immediate: true }
    );

    return {
      tabs,
      onTabClick,
      sidebarActionType,
      getFreeShippingThresholdInfoText,
      hasOneBooxiItemInCart,
      close,
      activeTabIndex,
      isFreeShippingEnabled,
      activeComponent,
      total,
      isMerging,
      validateFraction
    };
  }
});
