import { useContext } from '@nuxtjs/composition-api';

import { useRulesInterface } from '~/diptyqueTheme/composable/useRules/types';

export function useRules(): useRulesInterface {
  const {
    app: { i18n }
  } = useContext();
  const code = i18n.localeProperties.code || i18n.defaultLocale;
  const getRules = (config: Record<string, string>): string => {
    return config[code] || config['default'];
  };

  return {
    getRules
  };
}
