<template>
  <transition name="sf-fade" mode="out-in">
    <SfLoader class="vaimo-loader" :loading="loading">
      <template #loader>
        <div :class="circle ? 'vaimo-loader__img-circle' : 'vaimo-loader__img'"></div>
      </template>
    </SfLoader>
  </transition>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';
export default defineComponent({
  name: 'VaimoLoader',
  components: {
    SfLoader
  },
  props: {
    loading: {
      default: true,
      type: Boolean
    },
    circle: {
      default: false,
      type: Boolean
    }
  }
});
</script>

<style lang="scss" scoped>
.vaimo-loader {
  &__img {
    //noinspection CssUnknownTarget
    background-image: url('~/diptyqueTheme/assets/images/loader.gif');
    background-repeat: no-repeat;
    background-size: contain;
    transform-origin: top left;
    height: 200px;
    width: 160px;
  }

  &__img-circle {
    //noinspection CssUnknownTarget
    background-image: url('~/diptyqueTheme/assets/images/circle-loader.gif');
    background-repeat: no-repeat;
    background-size: contain;
    transform-origin: top left;
    height: 50px;
    width: 50px;
  }

  ::v-deep {
    .sf-loader__overlay {
      @apply z-high;
      background: rgba(255, 255, 255, 0.8);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
