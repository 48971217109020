import { parseStringToDate } from '~/helpers/dateHelpers';

declare const BookNow: any;
import { computed, useContext, useRouter } from '@nuxtjs/composition-api';

import { useApi, useConfig, useUiNotification } from '~/composables';
import type {
  AddBooxiProductToCartResponse,
  BooxiConfigInterface,
  BooxiLifeTimeInterface,
  UseBooxiInterface
} from '~/diptyqueTheme/composable/useBooxi/types';
import { lsRemove, lsSet } from '~/diptyqueTheme/composable/useLocalStorage';
import addBooxiProductToCartMutation from '~/diptyqueTheme/customQueries/magento/addBooxiProductToCartMutation';
import { useProductStore } from '~/modules/catalog/product/stores/product';
import useCart from '~/modules/core/composables/useCart';
import { useCartStore } from '~/modules/core/stores/cart';
import type { Cart } from '~/modules/GraphQL/types';

export function useBooxi(): UseBooxiInterface {
  const { config } = useConfig();
  const { mutate } = useApi();
  const { load } = useCart();
  const router = useRouter();

  const { send: sendNotification } = useUiNotification();
  const {
    app: { i18n }
  } = useContext();
  const cartStore = useCartStore();

  const booxiConfig = computed<BooxiConfigInterface>(() => ({
    is_booxi_enabled: config.value.booxi_appointment_scheduling_enabled,
    booxi_script_url: config.value.booxi_appointment_scheduling_script_url,
    booxi_api_url: config.value.booxi_appointment_scheduling_api_url,
    booxi_api_key: config.value.booxi_appointment_scheduling_merchant_api_key,
    booxi_language: config.value.booxi_appointment_scheduling_language,
    booxi_fallback_language: config.value.booxi_appointment_scheduling_fallback_language,
    booxi_on_abort_redirect_to: config.value.booxi_appointment_scheduling_on_abort_redirect_to
  }));

  const booxiLifeTimeData = computed((): BooxiLifeTimeInterface => {
    return {
      isAutoCloseEnabled: config.value.booxi_appointment_scheduling_auto_close_enabled,
      lifetime: config.value.booxi_appointment_scheduling_auto_close_time
    };
  });

  const triggerBooxiModal = (config?: Record<string, unknown>): void => {
    const { is_booxi_enabled, booxi_api_key, booxi_language, booxi_fallback_language, booxi_on_abort_redirect_to } = booxiConfig.value;

    if (!is_booxi_enabled) {
      return;
    }

    // onBookedRedirectTo: 'https://localhost:3000/fr_fr/cart', // Only for dev purposes
    BookNow.open({
      apiKey: booxi_api_key,
      language: booxi_language,
      fallbackLanguage: booxi_fallback_language,
      onAbortRedirectTo: booxi_on_abort_redirect_to,
      client: { remindByEmail: true, remindBySMS: true, hideAdditionalRequest: true, skipClientForm: false },
      iframeId: 'diptyque-booxi-iframe',
      ...config
    });
  };

  const hasOneBooxiItemInCart = computed(() => {
    const items = cartStore?.cart?.items;

    return items?.length === 1 && items[0].product.__typename === 'VirtualProduct';
  });

  const destroyBooxiReservation = () => {
    lsRemove('booxi-time');
  };

  const addBooxiProductToCart = async (booxiData: string): Promise<void> => {
    try {
      cartStore.is_booxi_loading = true;
      if (!cartStore.initial_loading) {
        cartStore.initial_loading = true;
      }
      const { data, errors }: AddBooxiProductToCartResponse = await mutate(addBooxiProductToCartMutation, {
        cart_id: cartStore?.cart.id,
        encoded_booxi_products: booxiData
      });
      if (errors?.length && errors[0]) {
        sendNotification({
          id: Symbol('booxi_error'),
          message: errors[0].message,
          type: 'danger',
          persist: false
        });

        return;
      }

      const productItem = data?.addBooxiBookingProductsToCart?.cart?.items?.find((item) => item.product.__typename === 'VirtualProduct');
      lsSet('booxi-time', new Date());
      await load();

      const cartResponse = data?.addBooxiBookingProductsToCart?.cart as unknown as Cart;
      if (cartResponse?.freeshipping_threshold_info) {
        const productStore = useProductStore();
        productStore.setFreeShippingInfo(cartResponse?.freeshipping_threshold_info);
      }

      sendNotification({
        id: Symbol('product_add'),
        message: productItem.product.name,
        type: 'success',
        persist: false,
        title: i18n.t('Product added') as string,
        additional_info: {
          booxi_item_info: productItem?.booxi_item_info,
          product: productItem.product
        },
        action_type: 'add_to_cart'
      });
      router.replace({ query: {} });
    } catch (error) {
      console.error('addBooxiProductToCart', error);
    } finally {
      cartStore.is_booxi_loading = false;
      cartStore.initial_loading = false;
    }
  };

  const expirationBooxiItemTime = computed(() => {
    const item = cartStore.cart?.items?.find((product) => product.booxi_item_info.is_booxi && product.booxi_item_info.expired_time !== 'false');
    if (item?.booxi_item_info) {
      return parseStringToDate(item.booxi_item_info.expired_time);
    }
    return null;
  });

  const checkIsBooxiExpired = () => {
    const hasExpiredBooking = cartStore.cart.booxi_booking_info?.cart_has_expired_booking;
    const isBooxiItemExpired = expirationBooxiItemTime.value && new Date() >= expirationBooxiItemTime.value;

    return hasExpiredBooking || isBooxiItemExpired;
  };

  const sendBooxiExpiredMessage = (): void => {
    const expiredMessage =
      cartStore.cart.booxi_booking_info?.error_message || (i18n.t('Your booking is outdated, please remove it from your cart') as string);

    sendNotification({
      id: Symbol('booxi_expired_message'),
      message: expiredMessage,
      type: 'warning',
      persist: false
    });
  };

  return {
    triggerBooxiModal,
    addBooxiProductToCart,
    sendBooxiExpiredMessage,
    checkIsBooxiExpired,
    hasOneBooxiItemInCart,
    booxiLifeTimeData,
    booxiConfig,
    destroyBooxiReservation
  };
}
