import type { Cart as NostoCart, CartItem as NostoCartItem } from '@nosto/types';

import type { Cart, CartItemInterface } from '~/modules/GraphQL/types';

/**
 * Maps cart items from the GraphQL schema to the NostoCartItem structure.
 * @param items - An array of CartItemInterface objects.
 * @returns An array of NostoCartItem objects.
 */
const mapCartItems = (items: CartItemInterface[] = []): NostoCartItem[] =>
  items.map((item) => ({
    product_id: String(item?.product?.id ?? ''),
    sku_id: item?.product?.sku ?? '',
    name: item?.product?.name ?? '',
    unit_price: item?.product?.price_range?.minimum_price?.final_price?.value ?? 0,
    price_currency_code: item?.product?.price_range?.minimum_price?.final_price?.currency ?? 'USD',
    quantity: item?.quantity ?? 1
  }));

/**
 * Maps a Cart object from the GraphQL schema to the NostoCart structure.
 * @param cart - A Cart object.
 * @returns A NostoCart object.
 */
export default function mapCart(cart: Cart): NostoCart {
  return {
    hcid: cart.id,
    items: mapCartItems(cart.items)
  };
}
