import { computed, reactive } from '@nuxtjs/composition-api';

import { useConfig } from '~/composables';

import { LocaleMaskPropertiesInterface, MaskPropertiesInterface, UsePhoneMaskInterface } from './types';

export function usePhoneMask(): UsePhoneMaskInterface {
  const { config } = useConfig();

  const maskProperties: MaskPropertiesInterface = reactive({
    usStore: {
      mask: '(###) ###-####',
      maxLength: 14, // default length + empty spaces in mask
      placeholder: '(XXX) XXX-XXXX'
    },
    default: {
      mask: '## ## ## ## ## ## ## ## ## ## #',
      maxLength: 31, // default length + empty spaces in mask
      placeholder: 'XX XX XX XX XX'
    }
  });

  const isUsStore = computed<boolean>(() => ['en_us', 'fr_us'].includes(config.value.store_code));

  const getMaskProperties = computed<LocaleMaskPropertiesInterface>(() => {
    return isUsStore.value ? maskProperties.usStore : maskProperties.default;
  });

  const clearMask = (phone_number: string): string => {
    return phone_number?.replace(/\D/g, '');
  };

  const setMask = (phone_number: string): string => {
    return phone_number?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };

  const { mask, maxLength, placeholder } = getMaskProperties.value;

  return {
    mask,
    maxLength,
    placeholder,
    clearMask,
    setMask
  };
}

export default usePhoneMask;
